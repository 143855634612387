// Libs
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

// Components
import { Input, Select, Table, Pagination, Tooltip, Button, Badge as AntBadge, Typography, Modal } from 'antd';
import Badge, { getBadgeType, BadgeType, BadgeSize } from 'components/badge';
import Progress, { calculatePercentage } from 'components/progress';
import Dropdown, { Action as DropdownAction } from 'components/dropdown';
import FilterComponent from 'components/basic-list/Filters';
import BulkAssignModal from 'components/bulk-operation/BulkAssignModal';
import AddResourceModal from 'components/bulk-operation/add-resource/AddResourceModal';
import RemoveResourceModal from 'components/bulk-operation/remove-resource/RemoveResourceModal';
import UploadDocumentsModal from 'components/bulk-operation/upload-documents/UploadDocumentsModal';
import WorkflowTransitionModal from 'components/bulk-operation/workflow-transition/WorkflowTransitionModal';
import ChangeFieldValueModal from 'components/bulk-operation/change-field-value/ChangeFieldValueModal';

// Icons
import { ReactComponent as FilterIcon } from 'assets/svg/filter.svg';
import Icon, { CommentOutlined, UserOutlined, FileOutlined, LoadingOutlined } from '@ant-design/icons';

// Services
import { getFormatedDate, getNumberFormatProps, getUserSetting } from 'services/settings';
import Notification from 'services/notification';

// Interfaces
import AppState from 'store/AppState.interface';

// Utils
import { findFirst, isNumeric } from 'utils/utils';
import diff from 'utils/htmldiff';

// Services
import { Api } from 'services/api';

// Styles
import './BasicList.scss';

const API: Api = new Api();
const { Search } = Input;
const { Option } = Select;
const { Link } = Typography;

const messages = defineMessages({
  create: {
    id: 'general.create',
    defaultMessage: 'Create',
    description: '',
  },
  filter_by: {
    id: 'basic_list_view.filter_by',
    defaultMessage: 'Filter by',
    description: '',
  },
  show: {
    id: 'basic_list_view.show',
    defaultMessage: 'Show',
    description: '',
  },
  entries_of: {
    id: 'basic_list_view.entries_of',
    defaultMessage: 'Entries of',
    description: '',
  },
  quick_filter: {
    id: 'basic_list_view.quick_search',
    defaultMessage: 'Quick Search',
    description: '',
  },
  filter: {
    id: 'basic_list_view.filter',
    defaultMessage: 'Filter',
    description: '',
  },
});

export interface Action {
  node: React.ReactElement;
  isLoading?: boolean;
};

export interface BulkOperationElement {
  type: string;
  title: string;
  reference: string;
  options: any[];
  dependency_element?: string;
};

export interface BulkOperation {
  title: string;
  reference: string;
  skip_notifications?: boolean;
  elements: { [key: string]: BulkOperationElement };
};

export type SizeType = 'small' | 'middle' | 'large' | undefined;

export interface List {
  columns: ListColumns[];
  data: ListData[];
  config: {
    nestable: boolean;
    can_create: boolean;
  };
  permissions: {
    [key: string]: boolean;
  };
};

export interface ListColumns {
  id: string;
  type: string;
  options: any;
  label: string;
  width: number;
  sorter: boolean;
  filterable: boolean;
  exportable?: boolean;
  hidden?: boolean;
  ellipsis: boolean;
  fixed: string | boolean;
};

export interface ListData {
  [key: string]: ListDataValues;
  children: any;
};

export interface ListDataValues {
  type: string;
  value: any;
  title?: string;
  total?: number;
  value_total?: number;
  prefix?: string | null;
  suffix?: string | null;
  format?: string | null;
  text?: string | number;
  color?: string;
  code?: string;
  unit?: string;
  old?: string;
  new?: string;
  size?: BadgeSize;
  path?: string;
  filename?: string;
};

export interface Config {
  can_create: boolean;
  nestable: boolean;
  conversions: Conversion;
  create_options: CreateOption[];
  bulk_operations: BulkOperation[];
};

export interface CreateOption {
  label: string;
  bundle: string;
  type: string;
  disabled?: boolean;
};

export interface Conversion {
  currencies: CurrencyConversion[];
  measurements: MeasurementConversion[];
};

export interface CurrencyConversion {
  id: number;
  title: string;
  code: string;
  rate: number;
  prefix: string | null;
  suffix: string | null;
};

export interface MeasurementConversion {
  id: number;
  title: string;
  unit: string;
  rate: number;
  prefix: string | null;
  suffix: string | null;
};

interface Props {
  client_id?: number;
  columns: any;
  items: any;
  config?: Config | null;
  rightActions?: Action[];
  defaultFilters?: any;
  intl: IntlShape;
  size?: SizeType;
  rawData?: boolean;
  extraColumns?: any;
  isLoading?: boolean;
  dateRangeFilterFields?: string[];
  defaultSortOrder?: { field: string, order: 'ascend' | 'descend' };
  exportTitle?: string;
  defaultExpandAllRows?: boolean;
  hideFilter?: boolean;
  emptyElement?: React.ReactNode;
};

interface State {
  columns: any;
  items: any;
  currency: string | null;
  measurement: string | null;
  currentPage: number;
  itemsPerPage: number;
  sorter: any;
  showFilter: boolean;
  quickFilter: any;
  tableHeight: number;
  tableWidth: number;
  sublistItems: any[];
  activeSublist: any;
  isExporting: boolean;
  selectedRowKeys: any[];
  bulkOperation: string | null;
};

export const recordDataMapping = (data: ListData[]) => {
  return data && data.map((entity: ListData) => {
    const appendChildrenKeys = (children: any) => {

      // Prevent nesting
      if (_.isEmpty(children)) return null;

      return children.map((childEntity: ListData) => {
        return {
          ...childEntity,
          'key': _.has(childEntity, 'key') ? childEntity.key : childEntity.id,
          'id': childEntity.id,
          'label': childEntity?.label || childEntity.title,
          'children': appendChildrenKeys(childEntity.children),
          ...childEntity.values,
        };
      });
    };

    return {
      ...entity,
      'key': _.has(entity, 'key') ? entity.key : entity.id,
      'id': entity.id,
      'label': entity?.label || entity.title,
      'children': appendChildrenKeys(entity.children),
      ...entity.values,
    };
  });
};

class BasicListView extends React.Component<Props, State> {

  component: any = React.createRef(); // This is used to figure out when the component is rendered
  filterComponent: any = React.createRef();

  state: State = {
    columns: this.props.columns,
    items: recordDataMapping(this.props.items),
    currency: getUserSetting('currency'),
    measurement: getUserSetting('measurement'),
    currentPage: 1,
    itemsPerPage: !!getUserSetting('results_per_page') ? Number(getUserSetting('results_per_page')) : 25,
    sorter: !_.isEmpty(this.props.defaultSortOrder) ? this.props.defaultSortOrder : null,
    showFilter: !_.isEmpty(this.props.defaultFilters),
    quickFilter: null,
    tableHeight: 0,
    tableWidth: 0,
    sublistItems: [],
    activeSublist: null,
    isExporting: false,
    selectedRowKeys: [],
    bulkOperation: null
  };

  componentDidMount = async () => {
    if (this.component) {
      this.heightObserver();
    }
  };

  componentDidUpdate = (prevProps: Props) => {

    // Update columns if needed
    if (!_.isEqual(this.props.columns, this.state.columns)) {
      this.setState({
        columns: this.props.columns
      });
    }

    // Update items if needed
    if (!_.isEqual(this.props.items, prevProps.items)) {
      this.setState({
        items: this.props.items
      });
    }

    if (this.component) {
      this.heightObserver();
    }
  };

  heightObserver = () => {
    const root: number = document.getElementById('root')?.offsetHeight || 0;
    const header: number = document.getElementById('Header')?.offsetHeight || 0;
    const jumbotron: number = document.getElementById('Jumbotron')?.offsetHeight || 0;
    const tabViewBar: number = document.getElementById('TabView-bar')?.offsetHeight || 0;
    const filtersHeight: number = document.getElementById('BasicListFilter')?.offsetHeight || 0;
    const tableHeight: number = root - (header + jumbotron + tabViewBar + filtersHeight + 130); // 120 is spill from misc margins and paddings that's to small to target.

    if (this.state.tableHeight !== tableHeight) {
      this.setState({
        tableHeight: tableHeight
      });
    }

    const rootWidth = document.getElementById('root')?.offsetWidth || 0;
    const layoutMenu = document.getElementById('Layout-menu')?.offsetWidth || 0;
    const tableWidth = rootWidth - (layoutMenu + 160); // 160 is sum of margins and paddings around the table

    if (this.state.tableWidth !== tableWidth) {
      this.setState({
        tableWidth: tableWidth
      });
    }
  };

  totalSublistItems = (values: any) => {
    let count = 0;

    _.isArray(values) ? values.forEach((value: any) => {
      const check = (_value: any) => {
        count++;
        if (_.has(_value, 'children') && !_.isEmpty(_value.children)) {
          _value.children.forEach((__value: any) => {
            check(__value);
          });
        }
      };

      check(value);
    }) : [];

    return count;
  };

  renderColumnTitle = (column: ListColumns) => {
    let label = column.label;

    if (column.type === 'currency') {
      label = `${label} (${this.state.currency})`;
    }

    if (column.type === 'area') {
      label = `${label} (${this.state.measurement})`;
    }

    return label;
  };

  recordColumnMapping = (config: Config | null | undefined, columns: ListColumns[] = []) => {
    return columns && columns
      .map((column: ListColumns, index: number) => {
        return {
          ...column,
          key: column.id,
          dataIndex: column.id,
          title: this.renderColumnTitle(column),
          options: _.has(column, 'options') ? column.options : [],
          type: column.type,
          fixed: _.has(column, 'fixed') && !!column.fixed ? column.fixed : undefined,
          render: (field: ListDataValues, row: any) => {

            if (_.isEmpty(field) || !_.has(field, 'type')) return <>-</>;

            const total = _.has(field, 'total') && field.total;

            switch (field.type) {
              case 'text':
              case 'string':
                return <>{ _.has(field, 'value') && !!field.value ? field.value : '-' }</>;
              case 'comparison':

                if (!_.has(field, 'old')) return <>-</>;

                return (
                  <div
                    className="Editor Editor--disabled"
                    dangerouslySetInnerHTML={{ __html: diff(field.old || '', _.has(field, 'new') ? field.new : '') }} >
                  </div>
                );
              case 'route':

                if (!_.has(field, 'value.title')) return <>-</>;

                if (!_.has(field, 'value.path') || !field.value.path) return <>{ field.value.title }</>;

                return (
                  <RouterLink className='d-f primaryColor' to={ field.value.path }>
                    { field.value.title }
                  </RouterLink>
                );
              case 'hyperlink':

                if (!_.has(field, 'value.title')) return <></>;

                if (!_.has(field, 'value.path') || !field.value.path) return <>{ field.value.title }</>;

                return (
                  <a className='d-f primaryColor' href={ field.value.path }>
                    { field.value.title }
                  </a>
                );
              case 'quick_links':
                const entries = Object.entries<{ count: number, new: boolean, path: string, description: string }>(field.value);
                const quickLinks = entries.map(([key, entity]) => {

                  const hasValues = entity.count > 0;

                  switch (key) {
                    case 'comments':
                      return (
                        <RouterLink key={ `${column.id}-${key}-${index}` } className='mR-25' to={ entity.path }>
                          <Tooltip
                            placement="top"
                            title={ !!entity.description ? entity.description : 'Comments' }
                          >
                            <AntBadge dot={ !!entity.new }>
                              <CommentOutlined className={ classNames('fsz-md op-50p primaryColor', { 'op-100p': hasValues }) } />
                            </AntBadge>
                          </Tooltip>
                        </RouterLink>
                      );

                    case 'resources':
                      return (
                        <RouterLink key={ `${column.id}-${key}-${index}` } className='mR-25' to={ entity.path }>
                          <Tooltip
                            placement="top"
                            title={ !!entity.description ? entity.description : 'Resources' }
                          >
                            <AntBadge dot={ !!entity.new }>
                              <UserOutlined className={ classNames('fsz-md op-50p primaryColor', { 'op-100p': hasValues }) } />
                            </AntBadge>
                          </Tooltip>
                        </RouterLink>
                      );

                    case 'documents':
                      return (
                        <RouterLink key={ `${column.id}-${key}-${index}` } className='mR-25' to={ entity.path }>
                          <Tooltip
                            placement="top"
                            title={ !!entity.description ? entity.description : 'Documents' }
                          >
                            <AntBadge dot={ !!entity.new }>
                              <FileOutlined className={ classNames('fsz-md op-50p primaryColor', { 'op-100p': hasValues }) } />
                            </AntBadge>
                          </Tooltip>
                        </RouterLink>
                      );
                    default:
                      return null;
                  }

                });

                return <div className="d-f">{ quickLinks }</div>;
              case 'datetime':
                if (!field.value) {
                  return '-';
                }

                // Generate the badge if a color is set on the field
                let badge = <span></span>;
                let badgeColor = BadgeType.Default;
                if (_.has(field, 'color')) {
                  switch (_.toUpper(field.color)) {
                    case 'GREEN':
                      badgeColor = BadgeType.Success;
                    break;
                    case 'AMBER':
                      badgeColor = BadgeType.Warning;
                    break;
                    case 'RED':
                      badgeColor = BadgeType.Danger;
                    break;
                  }

                  badge = <Badge type={ badgeColor } text={ field.text } size={ BadgeSize.Small } />;
                }

                // Format datetime into date or datetime
                const datetime = (_.has(field, 'format') && field.format === 'date' ? getFormatedDate(field.value, undefined, false) : getFormatedDate(field.value, undefined, true));

                return <div className="d-f"> <span className="mR-25"> { datetime } </span> { badge } </div>;
              case 'workflow_stage':
                return (
                  <Badge
                    fullWidth
                    type={ getBadgeType(field.value.context) }
                    text={ _.startCase(_.toLower(field.value.title)).split('_').join(' ') }
                  />
                );
              case 'number':
                const numberFieldElement = total ? (
                  <span>
                    <NumberFormat
                      { ...getNumberFormatProps() }
                      value={ field.value }
                      prefix={ field.prefix ? `${field.prefix}` : undefined }
                      suffix={ field.suffix ? `${field.suffix}` : undefined }
                      displayType={ 'text' }
                    />
                    <span> / </span>
                    <NumberFormat
                      { ...getNumberFormatProps() }
                      value={ total }
                      prefix={ field.prefix ? `${field.prefix}` : undefined }
                      suffix={ field.suffix ? `${field.suffix}` : undefined }
                      displayType={ 'text' }
                    />
                  </span>
                ) : (
                  <NumberFormat
                    { ...getNumberFormatProps() }
                    value={ field.value }
                    prefix={ field.prefix ? `${field.prefix}` : undefined }
                    suffix={ field.suffix ? `${field.suffix}` : undefined }
                    displayType={ 'text' }
                  />
                );
                return (
                  <div className="ta-r">
                    { _.has(field, 'path') && field.path ? (
                        <RouterLink className='primaryColor' to={ field.path }>
                          { numberFieldElement }
                        </RouterLink>
                      ) : (
                        numberFieldElement
                      )
                    }
                  </div>
                );
              case 'integer':
                return (
                  <div className="ta-r">
                    { total ? (
                        <span>{ field.prefix ? `${field.prefix} ` : '' }{ field.value }{ field.suffix ? ` ${field.suffix}` : '' } / {`${total}`}</span>
                      ) : (
                        <span>{ field.prefix ? `${field.prefix} ` : '' }{ field.value }{ field.suffix ? ` ${field.suffix}` : '' }</span>
                      )
                    }
                  </div>
                );
              case 'area':
                let areaValue = parseFloat(field.value);

                if (this.state.measurement) {
                  const activeMeasurement = config && config.conversions.measurements.find((_measurement: MeasurementConversion) => _measurement.unit === this.state.measurement);
                  if (activeMeasurement) {
                    const sourceUnit = config && config.conversions.measurements.find((_measurement: MeasurementConversion) => _measurement.unit === field.unit);
                    if (sourceUnit) {
                      areaValue = (areaValue / sourceUnit.rate) * activeMeasurement.rate;
                    }
                  }
                }

                const areaFieldElement = (
                  <NumberFormat
                    { ...getNumberFormatProps() }
                    value={ areaValue }
                    displayType={ 'text' }
                  />
                );

                return (
                  <div className="ta-r">
                    { _.has(field, 'path') && field.path ? (
                        <RouterLink className='primaryColor' to={ field.path }>
                          { areaFieldElement }
                        </RouterLink>
                      ) : (
                        areaFieldElement
                      )
                    }
                  </div>
                );
              case 'currency':
                let currencyValue = parseFloat(field.value);

                if (this.state.currency) {
                  const activeCurrency = config && config.conversions.currencies.find((_currency: CurrencyConversion) => _currency.code === this.state.currency);
                  if (activeCurrency) {
                    const sourceCurrency = config && config.conversions.currencies.find((_currency: CurrencyConversion) => _currency.code === field.code);
                    if (sourceCurrency) {
                      currencyValue = (currencyValue / sourceCurrency.rate) * activeCurrency.rate;
                    }
                  }
                }

                const currencyFieldElement = (
                  <NumberFormat
                    { ...getNumberFormatProps() }
                    value={ currencyValue }
                    displayType={ 'text' }
                  />
                );

                return (
                  <div className="ta-r">
                    { _.has(field, 'path') && field.path ? (
                        <RouterLink className='primaryColor' to={ field.path }>
                          { currencyFieldElement }
                        </RouterLink>
                      ) : (
                        currencyFieldElement
                      )
                    }
                  </div>
                );
              case 'local_currency':
                const localCurrencyFieldElement = (
                  <NumberFormat
                    { ...getNumberFormatProps() }
                    value={ parseFloat(field.value) }
                    displayType={ 'text' }
                  />
                );

                return (
                  <div className="ta-r">
                    { _.has(field, 'path') && field.path ? (
                        <RouterLink className='primaryColor' to={ field.path }>
                          { localCurrencyFieldElement }
                        </RouterLink>
                      ) : (
                        localCurrencyFieldElement
                      )
                    }
                  </div>
                );
              case 'sublist':
                if (!field.value || _.isEmpty(field.value)) {
                  return (
                    <div className="ta-r">-</div>
                  );
                } else if (field.value.length === 1 && _.isEmpty(field.value[0].children)) {
                  if (!_.has(field.value[0], 'path')) {
                    return <>{ total ? `${field.value[0]['title']} / ${total}` : field.value[0]['title'] }</>;
                  }

                  return (
                    <RouterLink className='primaryColor' to={ field.value[0]['path'] }>
                      { total ? `${field.value[0]['title']} / ${total}` : field.value[0]['title'] }
                    </RouterLink>
                  );
                } else {
                  const columns = [
                    {
                      key: 'title',
                      dataIndex: 'title',
                      title: 'Name',
                      render: (__: any, item: any) => {
                        if (!item?.path) {
                          return <>{ item?.title || '-' }</>;
                        };

                        return (
                          <RouterLink className='primaryColor' to={ item.path }>
                            { item.title }
                          </RouterLink>
                        );
                      },
                      sorter: true,
                      ellipsis: true,
                    }
                  ];
                  const dataSource = (data: any = this.state.sublistItems) => {
                    return data.map((item: any) => {
                      return {
                        'key': `${item.bundle}-${item.type}-${item.id}`,
                        'title': item.title,
                        'path': item.path,
                        'children': !_.isEmpty(item.children) ? dataSource(item.children) : null,
                      };
                    });
                  };
                  return (
                    <>
                      <Link onClick={ () => this.setState({ activeSublist: `${column.id}-${row.id}`, sublistItems: field.value }) }>
                        <div className="ta-r">
                          { this.getSublistLinkTitle(field) }
                        </div>
                      </Link>
                      { this.state.activeSublist === `${column.id}-${row.id}` &&
                        <Modal
                          centered
                          visible
                          title={ column.label || 'List' }
                          onCancel={ () => this.setState({ activeSublist: null, sublistItems: [] }) }
                          cancelText={ 'Close' }
                          style={{ maxHeight: '80vh', minWidth: 700 }}
                          okButtonProps={{ style: { display: 'none' } }}
                        >
                          <Table
                            className="ov-a"
                            style={{ height: 400 }}
                            size={ 'small' }
                            columns={ columns }
                            dataSource={ dataSource() }
                            pagination={ false }
                            expandable={{
                              defaultExpandAllRows: true
                            }}
                          />
                        </Modal>
                      }
                    </>
                  );
                }
              case 'badge':
                let type = BadgeType.Default;
                if (_.has(field, 'color')) {
                  switch (_.toUpper(field.color)) {
                    case 'GREEN':
                      type = BadgeType.Success;
                    break;
                    case 'AMBER':
                      type = BadgeType.Warning;
                    break;
                    case 'RED':
                      type = BadgeType.Danger;
                    break;
                  }
                }

                if (field.text === null) {
                  return <span>-</span>;
                }

                return (
                  <Badge type={ type } text={ field.text } size={ field.size } />
                );
              case 'progress':

                if (!_.has(field, 'total') || !field.total) {
                  return <>-</>;
                }

                return (
                  <Progress total={ field.total } value={ field.value } />
                );
              case 'nested':
                if (!_.has(field, 'title') || !field.title) {
                  return <>-</>;
                }

                return <>{ field.title  }</>;
            }
          },
          width: column?.width || 200,
          filterable: column.filterable,
          exportable: column.exportable === false ? false : true,
          sorter: column.sorter,
          ellipsis: column.ellipsis,
        };
      });
  };

  handleSort = (pagination: any, filters: any, sorter: any, extra: any) => {
    this.setState({
      sorter: sorter
    });
  };

  paginageItems = (items: any[], currentPage = 1, itemsPerPage = 25) => {
    return _.drop(items, (currentPage - 1) * itemsPerPage).slice(0, itemsPerPage);
  };

  sortData = (items: any[], sorter: any) => {

    const field = sorter.field;
    const metaitem = items[0];
    const order = sorter.order === 'descend' ? 'desc' : 'asc';
    const metafield = metaitem?.[field];

    if (typeof metafield === 'object') {
      switch (metafield.type) {
        case 'number':
        case 'area':
        case 'currency':
        case 'integer':
          return _.orderBy(items, item => _.has(item, `${field}.value`) ? parseFloat(item[field]['value']) : -1, order);
        case 'datetime':
          return _.orderBy(items, (item: any) => {
            if (_.has(item, `${field}.value`)) {
              return moment(item[field]['value'], 'YYYY-MM-DD HH:mm:ss').valueOf();
            } else {
              return '-';
            }
          }, order);
        case 'string':
          return _.orderBy(items, `${field}.value`, order);
        case 'route':
        case 'workflow_stage':
          return _.orderBy(items, `${field}.value.title`, order);
        case 'nested':
          return _.orderBy(items, `${field}.title`, order);
        case 'sublist':
          return items.sort((a: any, b: any) => {
            let aValue: any = '';
            let bValue: any = '';

            if (_.has(a[field], 'value')) {
              if (a[field].value.length === 1 && _.has(a[field], 'value[0].title') && !_.has(a[field], 'value_total')) {
                aValue = a[field].value[0].title;
              } else {
                aValue = this.totalSublistItems(a[field].value);
              }
            }

            if (_.has(b[field], 'value')) {
              if (b[field].value.length === 1 && _.has(b[field], 'value[0].title') && !_.has(b[field], 'value_total')) {
                bValue = b[field].value[0].title;
              } else {
                bValue = this.totalSublistItems(b[field].value);
              }
            }

            if (order === 'desc') {
              if (isNumeric(aValue) && isNumeric(bValue)) {
                return bValue - aValue;
              }
              return String(bValue) > String(aValue) ? 1 : -1;
            } else {
              if (isNumeric(aValue) && isNumeric(bValue)) {
                return aValue - bValue;
              }
              return String(bValue) > String(aValue) ? -1 : 1;
            }
          });
        case 'badge':
          return _.orderBy(items, `${field}.text`, order);
        case 'progress':
          return items.sort((a, b) => {
            if (order === 'desc') {
              return calculatePercentage(b[field].total, b[field].value) - calculatePercentage(a[field].total, a[field].value);
            } else {
              return calculatePercentage(a[field].total, a[field].value) - calculatePercentage(b[field].total, b[field].value);
            }
          });
      }
    }

    return _.orderBy(items, field, order);
  };

  quickFilter = (haystack: any[], needle: any) => {
    return haystack.filter((row: any) => {
      return Object.keys(row).some(columnKey => {

        const columnValue = row[columnKey];

        if (!!this.props.rawData) {
          return (typeof columnValue === 'string' || typeof columnValue === 'number') && columnValue.toString().toLowerCase().includes(needle.toLowerCase());
        }

        switch (columnValue?.type) {
          case 'nested':
            return !!columnValue?.title && columnValue.title.toLowerCase().includes(needle.toLowerCase());
          case 'currency':
            return !!columnValue?.value && columnValue.value.toString().toLowerCase().includes(needle.toLowerCase());
          case 'workflow_stage':
          case 'route':
            return !!columnValue?.value?.title && columnValue.value.title.toLowerCase().includes(needle.toLowerCase());
          case 'string':
          case 'area':
          case 'datetime':
            return !!columnValue?.value && columnValue.value.toLowerCase().includes(needle.toLowerCase());
        }

        return false;
      });
    });
  };

  getNestedFilterOptions = (filters: any, columns: any) => {
    Object.keys(filters).forEach((filter: any) => {
      const column = columns.find((column: any) => column.key === filter);
      if (column && column.type === 'nested') {
        const _filters = filters[filter].map((filterOptionId: number) => {
          const option = findFirst({ children: column.options }, 'children', { id: filterOptionId });
          return option.title;
        });
        filters[filter] = _filters;
      }
    });
    return filters;
  };

  exportFile = async (params: any) => {
    params.filters = this.getNestedFilterOptions(_.cloneDeep(params.filters), _.cloneDeep(params.columns));
    try {
      await API.downloadList(`client/${this.props.client_id}/report/export`, _.cloneDeep(params));
    } catch (error) {
      Notification('error', 'Export Failed');
    } finally {
      this.setState({
        isExporting: false
      });
    }
  };

  isWorkflowStageMatch = (entities: any[]): boolean => {
    const { items } = this.props;
    const filteredItems = items.filter((_item: any) => {
      return entities.includes(_item.id);
    });

    let match: boolean | null = null;
    let prevItemStage: string | null = null;
    filteredItems.forEach((_item: any) => {
      if (_.has(_item, 'values.workflow_stage.value.id')) {
        if (!prevItemStage) {
          prevItemStage =  _item.values['workflow_stage'].value.id;
        }

        match = match !== false && _item.values['workflow_stage'].value.id === prevItemStage;

        prevItemStage = _item.values['workflow_stage'].value.id;
      }
    });

    return !!match;
  };

  renderActions = (actions: Action[]) => {
    return actions.map((action: Action, index: number) => (
      <span key={ index } className={ classNames({ 'mL-5': !!index }) }>
        { !!action.isLoading ? (
          <LoadingOutlined className="fsz-def text-ant-default" />
        ) : (
          action.node
        ) }
      </span>
    ));
  };

  renderBulkOperations = (bulkOperations: BulkOperation[], entities: any[]) => {
    const bulkActions: DropdownAction[] = [
      {
        node: '',
        onClick: () => {}
      }
    ];

    bulkOperations.forEach((operation: BulkOperation) => {
      let disabled: boolean = false;
      let disabledMessage: string = 'No rows selected';

      if (operation.reference === 'workflow-transition') {
        disabled = !this.isWorkflowStageMatch(entities);
        disabledMessage = _.isEmpty(entities) ? 'No rows selected' : 'Records stages don\'t match' ;
      }

      bulkActions.push({
        node: operation.title,
        onClick: () => this.setState({ bulkOperation: operation.reference }),
        isLoading: false,
        disabled: _.isEmpty(entities) || disabled ? [disabledMessage] : false
      });
    });

    return (
      <span>
        <Dropdown actions={ bulkActions } />
      </span>
    );
  };

  bulkOperationOnSuccess = (responseData: any, callback?: () => void) => {
    this.setState({
      items: this.state.items.map((item: any) => responseData.find((responseItem: any) => responseItem.relation_id === item.relation_id) ? responseData.find((responseItem: any) => responseItem.relation_id === item.relation_id) : item),
      selectedRowKeys: [],
    }, () => {
      Notification('success', 'Operation was successful');
      callback && callback();
    });
  };

  renderBulkOperationModal = (bulkOperationRef: string, bulkOperations: BulkOperation[]) => {
    const bulkOperation: BulkOperation | undefined = bulkOperations.find((_bulkOperation: BulkOperation) => _bulkOperation.reference === bulkOperationRef);
    const { selectedRowKeys } = this.state;
    const { client_id, items } = this.props;

    if (bulkOperation) {
      const selectedEntities: any[] = [];

      selectedRowKeys.forEach((_selectedKey: any) => {
        const item = items.find((_item: any) => _item.id === _selectedKey);
        if (item) {
          selectedEntities.push({
            id: item.id,
            type: item.type,
            bundle: item.bundle
          });
        }
      });

      // Add bespoke component if a complex form logic needed. Default to
      switch (bulkOperation.reference) {
        case 'add-resource':
          return (
            <AddResourceModal
              clientId = { client_id }
              runEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/run` }
              checkEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/check` }
              entities={ selectedEntities }
              skipNotifications={ bulkOperation?.skip_notifications }
              onClose={ () => this.setState({ bulkOperation: null }) }
              onSuccess={ this.bulkOperationOnSuccess }
            />
          );

        case 'remove-resource':
          return (
            <RemoveResourceModal
              clientId = { client_id }
              runEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/run` }
              checkEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/check` }
              entities={ selectedEntities }
              skipNotifications={ bulkOperation?.skip_notifications }
              onClose={ () => this.setState({ bulkOperation: null }) }
              onSuccess={ this.bulkOperationOnSuccess }
            />
          );

        case 'workflow-transition':
          return (
            <WorkflowTransitionModal
              clientId = { client_id }
              runEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/run` }
              checkEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/check` }
              entities={ selectedEntities }
              onClose={ () => this.setState({ bulkOperation: null }) }
              onSuccess={ this.bulkOperationOnSuccess }
            />
          );

        case 'upload-documents':
          return (
            <UploadDocumentsModal
              clientId = { client_id }
              runEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/run` }
              checkEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/check` }
              entities={ selectedEntities }
              onClose={ () => this.setState({ bulkOperation: null }) }
              onSuccess={ this.bulkOperationOnSuccess }
            />
          );

        case 'change-field-value':
          return (
            <ChangeFieldValueModal
              clientId = { client_id }
              runEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/run` }
              checkEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/check` }
              entities={ selectedEntities }
              onClose={ () => this.setState({ bulkOperation: null }) }
              onSuccess={ (response: any[]) => {
                this.bulkOperationOnSuccess(response, () => {
                  this.setState({ bulkOperation: null });
                });
              } }
            />
          );

        default:
          return (
            <BulkAssignModal
              runEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/run` }
              checkEndpoint={ `client/${client_id}/bulk-operations/${bulkOperation.reference}/check` }
              elements={ bulkOperation.elements }
              entities={ selectedEntities }
              skipNotifications={ bulkOperation?.skip_notifications }
              onClose={ () => this.setState({ bulkOperation: null }) }
              onSuccess={ this.bulkOperationOnSuccess  }
            />
          );
      }
    }
  };

  getSublistLinkTitle = (field: ListDataValues) => {
    if (_.has(field, 'value_total')) {
      return field.value_total;
    }

    return !!field?.total ? `${this.totalSublistItems(field.value)} / ${field.total}` : this.totalSublistItems(field.value);
  };

  render = () => {
    const { intl: { formatMessage }, rightActions = [], size, rawData, extraColumns, isLoading, config, exportTitle, defaultExpandAllRows, hideFilter, emptyElement } = this.props;
    const {
      currentPage,
      itemsPerPage,
      showFilter,
      sorter,
      quickFilter,
      columns,
      items,
      tableHeight,
      tableWidth,
      currency,
      measurement,
      selectedRowKeys,
      bulkOperation
    } = this.state;

    let _columns = columns || [];
    let _data = items || [];

    const actions = [...rightActions];
    const filters = this.filterComponent && this.filterComponent?.state?.filters || [];

    if (!rawData) {
      _columns = this.recordColumnMapping(config, _columns);
      _data = recordDataMapping(_data);
    }

    // Quickfilter
    if (quickFilter) {
      _data = this.quickFilter(_data, quickFilter);
    }

    // Sort data
    if (_data && sorter) {
      _data = this.sortData(_data, sorter);
    }

    // Append extra columns
    if (extraColumns) {
      _columns.push(...extraColumns);
    }

    if (exportTitle) {
      actions.push(
        {
          node: (
            <Button
              onClick={ () => {
                const exportFileParams = {
                  title: exportTitle,
                  filters: filters,
                  columns: _columns,
                  data: _data,
                  config: {
                    currency: currency,
                    measurement: measurement,
                  },
                };
                this.setState({ isExporting: true }, () => this.exportFile(exportFileParams));
              } }
              loading={ this.state.isExporting }
            >
              Export
            </Button>
          ),
        }
      );
    }

    // Render row selection only if bulk operations sent
    let rowSelection = null;
    if (!!config?.bulk_operations) {
      rowSelection = {
        fixed: true,
        checkStrictly: false,
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys: any) => {
          this.setState({selectedRowKeys: selectedRowKeys});
        }
      };
    }

    const paginatedData = this.paginageItems(_data || [], currentPage, itemsPerPage);
    const gotFilters = _columns.find((column: any) => column.filterable) || false;

    return (
      <>
        <div id="BasicListFilter" ref={ node => (this.component = node) }>
          <div className="d-f jc-sb ai-c mB-20 mT-5">
            <div className="d-if">
              <Search
                disabled={ _.isEmpty(_columns) || hideFilter }
                placeholder={ formatMessage(messages.quick_filter) }
                style={{ width: 300 }}
                onBlur={ event => {
                  this.setState({
                    quickFilter: event.target.value || null,
                    currentPage: 1,
                  });
                }}
                onSearch={ value => {
                  this.setState({
                    quickFilter: value || null,
                    currentPage: 1,
                  });
                }}
              />
              { config && _.has(config, 'conversions.currencies') && !_.isEmpty(config.conversions.currencies) &&
                <Select
                  style={{ width: 200 }}
                  className="Select-Field mL-10"
                  onChange={ (code: string) => {
                    this.setState({
                      currency: code
                    });
                  } }
                  value={ this.state.currency ? this.state.currency : undefined }
                  placeholder={ 'Currency Conversion' }
                  disabled={ false }
                >
                  { config.conversions.currencies.map((currency: CurrencyConversion, index: number) => {
                    return (
                      <Option
                        key={ index }
                        value={ currency.code }
                      >
                        { `${currency.title} (${currency.code})` }
                      </Option>
                    );
                  }) }
                </Select>
              }
              { config && _.has(config, 'conversions.measurements') && !_.isEmpty(config.conversions.measurements) &&
                <Select
                  style={{ width: 200 }}
                  className="Select-Field mL-10"
                  onChange={ (unit: string) => {
                    this.setState({
                      measurement: unit
                    });
                  } }
                  value={ this.state.measurement ? this.state.measurement : undefined }
                  placeholder={ 'Measurement Conversion' }
                  disabled={ false }
                >
                  { config.conversions.measurements.map((measurement: MeasurementConversion, index: number) => {
                    return (
                      <Option
                        key={ index }
                        value={ measurement.unit }
                      >
                        { `${measurement.title} (${measurement.suffix ? measurement.suffix : '' })` }
                      </Option>
                    );
                  }) }
                </Select>
              }
            </div>
            <div className="d-if">
              { !_.isEmpty(actions) && this.renderActions(actions) }
              { this.renderBulkOperations(!!config?.bulk_operations ? config.bulk_operations : [], selectedRowKeys) }
            </div>
          </div>
          { !hideFilter &&
          <div>
            <div
              className="d-f jc-sb ai-c mB-10"
              style={{ userSelect: 'none' }}
            >
              <div className="d-if mL-10">
                <span>{ formatMessage(messages.show) }</span>
                <span className="mL-10 mR-10">
                  <Select
                    disabled={ (_.isEmpty(_columns) || _.isEmpty(_data)) }
                    size={ 'small' }
                    onChange={ (value: number) => {
                      this.setState({
                        currentPage: 1,
                        itemsPerPage: value
                      });
                    } }
                    defaultValue={ itemsPerPage }
                  >
                    <Option value={ 25 }>25</Option>
                    <Option value={ 50 }>50</Option>
                    <Option value={ 100 }>100</Option>
                  </Select>
                </span>
                <span>{ formatMessage(messages.entries_of)} <b>{_data.length || 0 }</b></span>
                <span
                  className={ classNames('mL-35', {
                    'link': gotFilters,
                    'text-ant-disabled disabled': !gotFilters || (_.isEmpty(_columns) || _.isEmpty(_data)),
                    'active': showFilter || !_.isEmpty(filters)
                  }) }
                  onClick={ () => {
                    this.setState({
                      showFilter: !showFilter
                    });
                  } }
                >
                  <Icon
                    component={ FilterIcon }
                  />
                  <span>{ formatMessage(messages.filter) }</span>
                </span>
              </div>
              <div className="d-if">
                <Pagination
                  disabled={ _data.length === 0 }
                  showSizeChanger={ false }
                  current={ currentPage }
                  total={ _data.length }
                  pageSize={ itemsPerPage }
                  onChange={ page => {
                    this.setState({
                      currentPage: page
                    });
                  } }
                />
              </div>
            </div>
            <div>
              { showFilter && (
                <FilterComponent
                  ref={ filterComponent => this.filterComponent = filterComponent }
                  columns={ _columns }
                  items={ recordDataMapping(this.props.items) }
                  dateRangeFilterFields={ this.props.dateRangeFilterFields }
                  defaultFilters={ this.props.defaultFilters }
                  onFilter={ (_items: any[]) => {
                    this.setState({
                      items: _items
                    });
                  } }
                />
              ) }
            </div>
          </div>
        }
        </div>
        <div className='Layout-box'>
          <Table
            sticky
            size={ size ? size : 'small' }
            columns={ _columns.filter((column: ListColumns) => !column?.hidden) }
            dataSource={ paginatedData }
            onChange={ this.handleSort }
            pagination={ false }
            loading={ isLoading }
            rowClassName='va-tp'
            scroll={{
              x: tableWidth || _columns.length * 200,
              y: tableHeight,
            }}
            expandable={{
              defaultExpandAllRows: defaultExpandAllRows
            }}
            locale={ emptyElement ? { emptyText: () => emptyElement } : undefined }
            rowSelection={ rowSelection ? rowSelection : undefined }
          />
        </div>
        { bulkOperation && this.renderBulkOperationModal(bulkOperation, !!config?.bulk_operations ? config.bulk_operations : []) }
      </>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
  };
};

export default connect(mapStateToProps, null)(injectIntl(BasicListView));
